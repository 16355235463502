<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'TATReports' }">TAT Reports</router-link>
            | <span class="title">Report Listings</span>
          </h1>
        </v-col>
      </v-row>

      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" md="12">
            <tat-reports-table
              @set-table-data="setTableData"
            ></tat-reports-table>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="mt-8">
        <v-row>
          <v-col class="mx-auto" cols="12" md="12">
            <div class="tw-flex tw-justify-end">
              <v-btn
                color="primary"
                :loading="isDownloading"
                :disabled="!reportsTableData.hasData"
                @click="downloadPdf"
              >
                <v-icon left> mdi-arrow-down-circle-outline </v-icon>Download
                PDF
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import FileSaver from 'file-saver'

export default {
  components: {
    tatReportsTable: () => import('@/components/TATReports/TATReportsTable'),
  },
  data: () => ({
    isDownloading: false,
    reportsTableData: {
      process: '',
      hasData: false,
      fromDate: '',
      toDate: '',
      page: 1,
      size: 10,
    },
  }),
  methods: {
    async downloadPdf() {
      this.isDownloading = true

      await axios
        .get(
          `/motor-assessment/api/reports/tat/${this.reportsTableData.process}/pdf`,
          {
            params: {
              fromDate: this.reportsTableData.fromDate,
              toDate: this.reportsTableData.toDate,
              page: this.reportsTableData.page,
              size: this.reportsTableData.size,
            },
            responseType: 'blob',
          }
        )
        .then((res) => {
          const contentDisposition = res.headers['content-disposition']
          const fileNameRegex = /filename="(.+?)"/
          const fileNameMatch = contentDisposition.match(fileNameRegex)
          const fileName =
            fileNameMatch && fileNameMatch[1]
              ? fileNameMatch[1]
              : `${this.reportsTableData.process}_TAT_REPORT.pdf`

          FileSaver.saveAs(res.data, fileName)
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.response.data.msg ?? 'Failed to download file',
            variant: 'error',
          })
        })
        .finally(() => (this.isDownloading = false))
    },
    setTableData(data) {
      this.reportsTableData = data
    },
  },
}
</script>
